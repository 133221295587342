/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './header'
import './layout.scss'

const Layout = ({ children }) => {
  const careerUrl = 'https://blog.komiyak.com/career-job-software-engineer'

  return (
    <>
      <nav className='container d-none d-sm-block'>
        <div className='row justify-content-end'>
          <div className='col-12'>
            <ul className='list-inline float-right ui-header-nav-style'>
              <li className='list-inline-item'>
                <a href="#about">会社情報</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <main>{children}</main>

      <footer className='container mt-3'>
        <div className='row'>
          <p className='col'>
            © {new Date().getFullYear()} Memento Mori Corp.
          </p>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
